.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mg20 {
  margin: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.container {
  position: relative;
  width: 600px;
  margin: auto;
}

.btn-upload {
  position: absolute !important;
  right: 10px;
}

.file-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  max-width: 300px;
  vertical-align: middle;
}

.list-group {
  padding: 0;
  margin: 0;
}

.list-header {
  margin-top: 10px !important;
}

.upload-message {
  font-weight: bold;
  color: #63A84E;
  margin-top: 20px !important;
}

.upload-message.error{
  color: #DA4148;
}

.btn-choose {
  margin-right: 10px !important;
}
